import axios from "axios";

const BIAuthentication = async () => {
  try {
    return await axios.get(process.env.REACT_APP_PBI_EMBED_TOKEN_ENDPOINT);
  } catch (error) {
    console.error(error);
  }
};

export default BIAuthentication;
