import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Box
      className="container"
      sx={{
        height: "100%",
        display: "flex",
        alignItem: "flex-start",
        justifyContent: "flext-start",
      }}
    >
      {/* Outlet is the same as a children transmited by react dom */}
      <Outlet />
    </Box>
  );
};

export default Layout;
