// import axios from "axios";

export const BIResumeCapacity = async () => {
  try {
    let response = await fetch(process.env.REACT_APP_PBI_RESUME_ENDPOINT, {
      method: "POST",
    });
    return {
      code: 200,
      message: response,
    };
  } catch (error) {
    console.error(error);
  }
};

export const BISuspendCapacity = async () => {
  try {
    let response = await fetch(process.env.REACT_APP_PBI_RESUME_ENDPOINT, {
      method: "POST",
    });
    return {
      code: 200,
      message: response,
    };
  } catch (error) {
    console.error(error);
  }
};
