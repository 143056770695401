import { Routes, Route, Link } from "react-router-dom";
import Layout from "./components/partials/Layout";
import IndexPage from "./pages";
import "./styles/index.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export default function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  // todo find how to use Auth0
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<IndexPage />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
