import { useAuth0 } from "@auth0/auth0-react";
import { LogoutOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

const Navigation = () => {
  const { logout } = useAuth0();

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
          width: "185px",
          height: "45px",
          py: 2,
        }}
      >
        <img
          src="/logo/root_insights_logo.svg"
          width="70"
          alt="root-insight logo"
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
          width: "185px",
          height: "45px",
          py: 2,
        }}
      >
        <Button
          fullWidth
          variant="text"
          color="primary"
          startIcon={<LogoutOutlined fontSize="small" />}
          onClick={async (e) => {
            e.preventDefault();
            await logout();
          }}
        >
          Logout
        </Button>
      </Box>
    </>
  );
};

export default Navigation;
