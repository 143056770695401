import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Navigation from "../components/partials/Navigation";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import config from "../components/configs/dieteren";
import BIAuthentication from "../components/lib/BIAuthentication";
import { BIResumeCapacity } from "../components/lib/BICapacities";
import moment from "moment";
import Loader from "../components/partials/Loader";

const IndexPage = () => {
  const [loading, setLoading] = useState(true);
  const [embed, setEmbed] = useState(null);
  const [lastAction, setLastAction] = useState({
    start: moment(),
    current: moment(),
  });

  useEffect(() => {
    document.title = `Root Insights | MEDIA DASHBOARD ${moment().format(
      "YYYY"
    )}`;

    try {
      BIResumeCapacity().then((response) => {
        BIAuthentication(config).then(({ data }) => {
          setEmbed(data);
          setLoading(false);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (lastAction.current.diff(lastAction.start, "seconds") > 60) {
      BIResumeCapacity().then((response) => {
        BIAuthentication(config).then(({ data }) => {
          setEmbed(data);
        });
      });
      setLastAction({
        start: moment(),
        current: moment(),
      });
    }
  }, [lastAction]);

  return (
    <>
      <Navigation />
      <Box sx={{ height: "99%", width: "100%", backgroundColor: "white" }}>
        {(!embed || loading) && (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <Loader />
          </Box>
        )}
        {embed && (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: config.reportId,
              accessToken: embed.accessToken,
              tokenType: models.TokenType.Embed,
              settings: {
                bars: {
                  actionBar: {
                    visible: false,
                  },
                },
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: true,
                    position: models.PageNavigationPosition.Left,
                  },
                },
                viewMode: models.ViewMode.View,
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function (event) {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function (event) {
                    console.log("rendered");
                    setLoading(false);
                    setLastAction({
                      ...lastAction,
                      current: moment(),
                    });
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log("error", event.detail);
                  },
                ],
                [
                  "visualClicked",
                  () => {
                    setLastAction({
                      ...lastAction,
                      current: moment(),
                    });
                  },
                ],
                [
                  "pageChanged",
                  () => {
                    setLastAction({
                      ...lastAction,
                      current: moment(),
                    });
                  },
                ],
              ])
            }
            cssClassName={"powerBiReport"}
            getEmbeddedComponent={(embeddedReport) => {
              console.log(embeddedReport);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default IndexPage;
